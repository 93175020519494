import { makeAutoObservable } from "mobx";
import { LOCALES } from "../i18n/locales";
import {IMaterial} from "../models/Material";
import {VideoItem} from "../models/VideoItem";
import {XImage} from "../models/XImage";
import {HomeMenuPage} from "../utils/defaults";
import {StorageKey} from "../utils/types";
import {isNullOrUndefined} from "../utils/utils";

export default class AdsNetStore {

  isAuth = false;
  token: string = "";
  userData: any = {};
  locale: string = LOCALES.ENGLISH;

  isActiveA: boolean = false;
  portfolioRendered: boolean = false;

  isBackCallVisible: boolean = false;
  isMobile: boolean = false;
  isMenuOpenned: boolean = false;
  isFaderVisible: boolean = true;
  isFaderShown: boolean = true;
  isContentShown: boolean = false;
  isHomeBlockVisible: boolean = false;

  basicPrice: any | null = null;
  basicPriceUrl: string = "";
  customPriceUrl: string = "";
  customPrice: any | null = null;

  isHeaderLocked: boolean = false;
  selectedSlide: number = 0;

  menuSelected: number = 0;

  refuseDrop: boolean = false;

  videos: VideoItem[] = [];
  
  isVideoInited: boolean = false;
  isMainLoaded: boolean = false;
  isContentVisible: boolean = true;

  isSubWindowFS: boolean = false;

  isPricePlayed: boolean = false;
  playedMaterial: number = 0;
  playedTiming: number = 0;
  pricedTiming: number = 0;
  isVideoPlayed: boolean = false;
  isVideoMuted: boolean = false;
  videoSpeed: number = 1.0;
  videoSpeeds: number[] = [1.0, 1.5, 2.0, 3.0];
  priceTiming: number = 15;
  priceTimings: number[] = [5, 10, 15, 20];

  selectedModule: string = "";
  selectedUserFilial: any | undefined = undefined;
  selMaterial: any | undefined = undefined;
  selMaterials: any[] = [];
  selUser: any | undefined = undefined;
  currentSlide: any = null;

  fs_image: string = "";
  fs_video: IMaterial | null = null;
  fs_video_index: number = 0;
  fs_videos_map: IMaterial[] = [];

  isPriceOrVideo: string = "";
  interIndex: any = null;

  usersOptions: any[] = [];
  usersOptionsA: any[] = [];
  users: any[] = [];
  usersFiltered: any[] = [];
  selectedUsersOption: string = "";
  selectedUsersOptionA: string = "";
  cities: any[] = [];
  citiesOpts: any[] = [];

  prices: any[] = [];
  videosList: IMaterial[] = [];
  materialsBaseList: any[] = [];
  materialsBaseListAll: any[] = [];
  selectedUser: any | undefined = undefined;
  selectedVideo: any | undefined = undefined;
  selectedPrice: any | undefined = undefined;
  selectedPrice1: any | undefined = undefined;
  userMaterials: any | undefined = undefined
  userTVMaterials: any | undefined = undefined
  userPrice: string = "";
  userPriceId: number = 0;

  uploadedImage: string = "";
  uploadedImages: string[] = [];
  uploadedImagesNames: string[] = [];

  isGalleryLoaded: boolean = false;
  searchText: string = "";

  cityName: string = "";
  cityNameTrans: string = "";

  materialName: string = "";
  userEmail: string = "";
  userName: string = "";
  userCity: string = "";
  userPassword: string = "";

  selectedCity: string = "moscow";

  materialLocked: boolean = false;
  selectedMaterial: string = "";
  materialsList: IMaterial[] = [];
  materials: any[] = [
    { label: "Видео", value: "video" },
    { label: "Изображение", value: "picture" }
  ];

  dragId: number = 0;

  opennedItem: number = 0;
  parsedUserId: number = 0;

  isImageViewVisible: boolean = false;
  selectedImages: XImage[] = [];
  selectedImageIndex: number = 0;

  selectedDirectory: any | null = null
  replaceDir: any | null = null

  selectedBranches: any[] = []

  tvDevices: any[] = []
  tvDeviceSelected: any | null = null
  tvSelectedDevice: any | null = null

  selectedDir: any = 0

  scrollPosBranches: number = 0

  constructor() {
    makeAutoObservable(this);
  }

  setScrollPosBranches(val: number) {
    this.scrollPosBranches = val
  }

  setTVSelectedDevice(val: any) {
    this.tvSelectedDevice = val
  }

  setTVDevices(val: any[]) {
    this.tvDevices = val
  }

  setTVDevSelected(val: any) {
    this.tvDeviceSelected = val
  }

  containsSelectedBranch(item: any) {
    const existsIx = this.selectedBranches.findIndex(v => v.id == item.id)
    if (existsIx === -1) {
      return false
    }
    return true
  }

  addSelectedBranch(item: any) {
    const existsIx = this.selectedBranches.findIndex(v => v.id == item.id)
    if (existsIx === -1) {
      this.selectedBranches.push(item)
    } else {
      this.selectedBranches = this.selectedBranches.filter(v => v.id != item.id)
    }
  }

  setSelectedBranch(items: any[]) {
    this.selectedBranches = items
  }

  setReplaceDir(val: any | null) {
    this.replaceDir = val
  }

  setSelectedDirectory(val: any | null) {
    this.selectedDirectory = val;
  }

  setIsImageViewVisible(val: boolean) {
    this.isImageViewVisible = val;
  }

  setSelectedImageIndex(val: number) {
    this.selectedImageIndex = val;
  }

  setSelectedImages(val: XImage[]) {
    this.selectedImages = val;
  }

  setParsedUserId(val: number) {
    this.parsedUserId = val;
  }

  setOpennedItem(val: number) {
    this.opennedItem = val;
  }

  setRefuseDrop(bl: boolean) {
    this.refuseDrop = bl;
  }

  setSelectedSlide(bl: number) {
    this.selectedSlide = bl;
  }

  setIsHomeBlockVisible(bl: boolean) {
    this.isHomeBlockVisible = bl;
  }

  setMenuSelected(item: number) {
    this.menuSelected = item;
  }

  setIsHeaderLocked(bl: boolean) {
    this.isHeaderLocked = bl;
  }

  setIsBackCallVisible(bl: boolean) {
    this.isBackCallVisible = bl;
  }

  setIsContentShown(bl: boolean) {
    this.isContentShown = bl;
  }

  setIsMobile(bl: boolean) {
    this.isMobile = bl;
  }

  setIsMenuOpenned(bl: boolean) {
    this.isMenuOpenned = bl;
  }

  setIsFaderVisible(bl: boolean) {
    this.isFaderVisible = bl;
  }

  setIsFaderShown(bl: boolean) {
    this.isFaderShown = bl;
  }

  setBasicPrice(price: any) {
    this.basicPrice = price;
    this.basicPriceUrl = price != null ? price.url : "";
  }

  setCustomPrice(price: any) {
    this.customPrice = price;
    this.customPriceUrl = price != null ? price.url : "";
  }


  setIsSubWindowFS(val: boolean) {
    this.isSubWindowFS = val;
  }

  setDragId(id: number) {
    this.dragId = id;
  }

  setCities(mn: any) {
    this.cities = mn;
  }

  setCitiesOpts(mn: any) {
    this.citiesOpts = mn;
  }

  setInterIndex(val: any) {
    this.interIndex = val;
  }

  setSelMaterial(mn: any) {
    this.selMaterial = mn;
  }

  setSelMaterials(mn: any) {
    this.selMaterials = mn;
  }

  addSelMaterial(mn: any) {
    const exists = this.selMaterials.findIndex(v => v.id == mn.id);
    if (exists == -1) {
      this.selMaterials.push(mn);
    } else {
      this.selMaterials = this.selMaterials.filter(v => v.id != mn.id);
    }
  }

  removeSelMaterial(id: number) {
    this.selMaterials = this.selMaterials.filter(v => v.id != id);
  }

  isMaterialSelected(id: number) {
    return this.selMaterials.findIndex(v => v.id == id) != -1;
  }

  setSelUser(mn: any) {
    this.selUser = mn;
  }

  setCityName(mn: string) {
    this.cityName = mn;
  }

  setCityNameTrans(mn: string) {
    this.cityNameTrans = mn;
  }

  setMaterialName(mn: string) {
    this.materialName = mn;
  }

  setPricedTiming(num: number) {
    this.pricedTiming = num;
  }

  setPlayedTiming(num: number) {
    this.playedTiming = num;
  }
  
  setPlayedMaterial(num: number) {
    this.playedMaterial = num;
  }

  setMaterialsList(mat: IMaterial[]) {
    this.materialsList = mat;
  }

  setMaterialLocked(mat: boolean) {
    this.materialLocked = mat;
  }

  setSelectedMaterial(mat: string) {
    this.selectedMaterial = mat;
    this.uploadedImages = [];
    this.uploadedImagesNames = [];
  }

  setSelectedDir(val: any) {
    this.selectedDir = val
  }

  setUserEmail(val: string) {
    this.userEmail = val;
  }

  setUserName(val: string) {
    this.userName = val;
  }

  setUserCity(val: string) {
    this.userCity = val;
  }

  setUserPassword(val: string) {
    this.userPassword = val;
  }

  setSearchText(txt: string) {
    this.searchText = txt;
  }

  setSelectedCity(val: string) {
    this.selectedCity = val;
  }

  setUploadedImage(image: string) {
    this.uploadedImage = image;
  }

  addUploadedImages(image: string) {
    this.uploadedImages.push(image);
  }

  removeUploadedImages(image: string) {
    this.uploadedImages = this.uploadedImages.filter((v: any) => v != image);
  }

  setUploadedImages(images: any) {
    this.uploadedImages = images;
  }

  addUploadedImagesNames(imageName: string) {
    this.uploadedImagesNames.push(imageName);
  }

  removeUploadedImagesNames(imageName: string) {
    this.uploadedImagesNames = this.uploadedImagesNames.filter((v: any) => v != imageName);
  }

  setUploadedImagesNames(images: any) {
    this.uploadedImagesNames = images;
  }

  setUsersOptionsA(opts: any[]) {
    this.usersOptionsA = opts;
  }

  setUsersOptions(opts: any[]) {
    this.usersOptions = opts;
  }

  setSelectedUsersOption(option: any) {
    this.selectedUsersOption = option;
  }
  setSelectedUsersOptionA(option: any) {
    this.selectedUsersOptionA = option;
  }

  setUsers(list: any[]) {
    this.users = list;
  }

  setUsersFiltered(list: any[]) {
    this.usersFiltered = list;
  }

  setIsGalleryLoaded(bl: boolean) {
    this.isGalleryLoaded = bl;
  }


  setPrices = (list: any[]) => this.prices = list;

  setMaterialsBaseListAll(val: any[]) {
    this.materialsBaseListAll = val;
  }

  setMaterialsBaseList(val: any[]) {
    this.materialsBaseList = val;
  }

  setVideosList(list: IMaterial[]) {
    this.videosList = list;
  }
  
  setUserPriceId(id: number) {
    this.userPriceId = id;
  }
  setUserPrice(price: any) {
    this.userPrice = price;
  }
  setUserTVMaterials(val: any) {
    this.userTVMaterials = val
  }
  setUserMaterials(sel: any | undefined) {
    this.userMaterials = sel;
  }
  setSelectedUser(sel: any | undefined) {
    this.selectedUser = sel;
  }
  setSelectedUserFilial(sel: any | undefined) {
    this.selectedUserFilial = sel;
  }
  setSelectedVideo = (sel: any | undefined) => this.selectedVideo = sel;
  setSelectedPrice = (sel: any | undefined) => this.selectedPrice = sel;

  setVideoSpeed(speed: number) {
    this.videoSpeed = speed;
  }

  setPriceTiming(timing: number) {
    this.priceTiming = timing;
  }

  setIsVideoMuted(bl: boolean) {
    this.isVideoMuted = bl;
  }

  setIsPricePlayed(bl: boolean) {
    this.isPricePlayed = bl;
  }

  setIsVideoPlayed(bl: boolean) {
    this.isVideoPlayed = bl;
  }

  setSelectedModule(mod: string) {
    this.selectedModule = mod;
  }

  setVideoItems(items: VideoItem[]) {
    this.videos = items;
  }

  setIsContentVisible(bl: boolean) {
    this.isContentVisible = bl;
  }

  setCurrentSlide(sl: any) {
    this.currentSlide = sl;
  }

  setIsMainLoaded(bl: boolean) {
    this.isMainLoaded = bl;
  }

  setIsVideoInited(bl: boolean) {
    this.isVideoInited = bl;
  }

  setFSVideoIndex(index: number) {
    this.fs_video_index = index;
  }

  setFSImage(image: string) {
    this.fs_image = image;
  }

  setIsPriceOrVideo(str: string) {
    this.isPriceOrVideo = str;
  }

  setFSVideo(video: IMaterial | null) {
    this.fs_video = video;
  }

  setFSVideosMap(map: any[]) {
    this.fs_videos_map = map;
  }

  setupAuth(token: string, user: any) {
    this.isAuth = true;
    this.userData = user;
    this.token = token;

    localStorage.setItem(StorageKey.USER, JSON.stringify(user));
    localStorage.setItem(StorageKey.AUTHED, "1");
    localStorage.setItem(StorageKey.TOKEN, token);
  }

  checkAuth() {
    const user = localStorage.getItem(StorageKey.USER);
    const token = localStorage.getItem(StorageKey.TOKEN);
    if (!isNullOrUndefined(user)) {
      this.isAuth = true;
      this.userData = JSON.parse(user);
      this.token = token || "";
    }
  }

}
